<template>
    <div>
        <navAdmin />

        <b-container>
          <h3 class="h2 pt-4 mb-3">
            <span>Create</span>
          </h3>
        
          <admin-input v-model="model.code" title="Code" name="code" :errors="getPromoCodeErrors" />
          <admin-input v-model="model.title" title="Title" name="title" :errors="getPromoCodeErrors" />
          <admin-input v-model="model.value" title="Discount rate" name="value" :errors="getPromoCodeErrors" />

          <b-form-checkbox-group
            id="packages"
            v-model="model.packages"
            name="packages"
            class="mt-2 mb-2"
          >
            <div class="h6">Packages</div>
            <b-form-checkbox v-for="p in getPackages" v-bind:key="p.id" :value="p.id">{{ p.title }} | ${{ p.price }}</b-form-checkbox>
          </b-form-checkbox-group>
          <b-form-checkbox-group
            id="specialPackages"
            v-model="model.packages"
            name="specialPackages"
            class="mt-2 mb-2"
          >
            <div class="h6">Special Packages</div>
            <b-form-checkbox v-for="p in getSpecialPackages" v-bind:key="p.id" :value="p.id">{{ p.title }} | ${{ p.price }}</b-form-checkbox>
          </b-form-checkbox-group>
          <admin-date-picker v-model="model.start_date" title="Start date" name="start_date" :errors="getPromoCodeErrors" />
          <admin-date-picker v-model="model.expiration_date" title="End date" name="expiration_date" :errors="getPromoCodeErrors" />
          <router-link class="btn btn-outline-secondary float-right ml-1" :to="{ name: 'DiscountList' }">Cancel</router-link>
          <b-button variant="primary" class="float-right" @click="save">Save</b-button>
        </b-container>
    </div>
</template>
<script>
import adminInput from '../Form/dnd-admin-input';
import adminDatePicker from '../Form/dnd-admin-datepicker';
import { notificationMixin } from '../../mixins/notification.mixin';
import navAdmin from './../../components/nav-admin';
import { mapGetters, mapActions } from 'vuex';

export default {
    mixins: [ notificationMixin ],
    computed: mapGetters([
      'getPackages',
      'getSpecialPackages',
      'getPromoCodeErrors',
      'getPromoCodeItem',
      'isDiscountCodeActionSuccess'
    ]),
    data() {
        return {
          model: {
            code: null,
            title: null,
            value: 0,
            packages: [],
            start_date: null,
            expiration_date: null
          }
        }
    },
    components: { 
      navAdmin, 
      adminInput, 
      adminDatePicker 
    },
    methods: { 
      ...mapActions([
        'fetchPackages',
        'createCode'
      ]),
      async save() {
        await this.createCode(this.model);
        
        if(this.isDiscountCodeActionSuccess) {
          this.$router.push({ name: 'EditDiscount', params: { 'code_id': this.getPromoCodeItem.id }});
          this.successNtf("Success!", "Promo Code created!");
        }
      }
    },
    mounted() {
      this.fetchPackages();
    }
}
</script>